import React from "react";
import ReactDOM from "react-dom";
import DashApp from "./dashApp";
import LogRocket from 'logrocket';
// import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import "antd/dist/antd.css";
import "./static/css/common.css";
import "./static/css/add.css";
import "./static/css/userManagement.css";
import "react-toastify/dist/ReactToastify.min.css";

LogRocket.init('whn5fi/mover-inventory');


ReactDOM.render(
	<>
		<DashApp />
		<ToastContainer newestOnTop={true} hideProgressBar={true} theme='light' autoClose={false} />
	</>,
	document.getElementById("root")
);

// serviceWorker.unregister();
