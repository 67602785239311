import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createHistory from "history/createBrowserHistory";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux/reducers";
import rootSaga from "../redux/sagas";

const history = createHistory({ basename: process.env.REACT_APP_BASE_NAME });
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

const store = createStore(
	combineReducers({
		...reducers,
		router: routerReducer,
	}),
	compose(
		applyMiddleware(...middlewares),
		window.devToolsExtension ? window.devToolsExtension() : (f) => f
	)
);

store.subscribe(() => {
	localStorage.setItem("company", store.getState().Auth.company);
	localStorage.setItem("fname", store.getState().Auth.fname);
	localStorage.setItem("lname", store.getState().Auth.lname);
	localStorage.setItem("adminid", store.getState().Auth.adminid);
	localStorage.setItem("isLogin", store.getState().Auth.isLogin);
	localStorage.setItem("access_token", store.getState().Auth.access_token);
	localStorage.setItem("companyID", store.getState().Auth.companyID);
	localStorage.setItem("staffId", store.getState().Auth.staffId);
	localStorage.setItem("pdf_time_stamp_checked", store.getState().Auth.pdf_time_stamp_checked);

});

sagaMiddleware.run(rootSaga);
export { store, history };
