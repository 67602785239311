import { Business, Dashboard, EmojiTransportation, People, Settings, AcUnit } from "@material-ui/icons";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BorderAllIcon from "@material-ui/icons/BorderAll";

import React from "react";
const options = [
	{
		key: "dashboard",
		label: "Dashboard",
		leftIcon: <Dashboard>Filled</Dashboard>,
	},

	{
		key: "group",
		label: "Groups",
		leftIcon: <AcUnit>Filled</AcUnit>,
	},
	
	{
		key: "company",
		label: "Company",
		leftIcon: <Business>Filled</Business>,
	},

	{
		key: "staff",
		label: "Users",
		leftIcon: <PersonAddIcon>Filled</PersonAddIcon>,
	},
	
	{
		key: "customer",
		label: "Customers",
		leftIcon: <People>Filled</People>,
	},

	{
		key: "job",
		label: "Shipments",
		leftIcon: <LocalShippingIcon>Outlined</LocalShippingIcon>,
	},

	{
		key: "generic-label",
		label: "Generic Labels",
		leftIcon: <BorderAllIcon>Outlined</BorderAllIcon>,
	},

	{
		key: "settings",
		label: "sidebar.settings",
		leftIcon: <Settings>Filled</Settings>,
		children: [
			{
				key: "shipment-type",
				label: "Shipment Type",
				leftIcon: <EmojiTransportation>Filled</EmojiTransportation>,
			},
			{
				key: "room-list",
				label: "Room List",
				leftIcon: <HomeWorkIcon>Filled</HomeWorkIcon>,
			},
			{
				key: "item-list",
				label: "Item List",
				leftIcon: <AddShoppingCartIcon>Filled</AddShoppingCartIcon>,
			},
			{
				key: "tag",
				label: "Tag Management",
				leftIcon: <LocalOfferIcon>Filled</LocalOfferIcon>,
			},
			{
				key: "external-api-user",
				label: "External API User",
				leftIcon: <CallSplitIcon>Filled</CallSplitIcon>,
			},
			// {
			//   key: "qr-code",
			//   label: "QR Code",
			//   leftIcon: "ion-ios-list",
			// },
		],
	},
	// {
	//   key: "change-password",
	//   label: "sidebar.changePassword",
	//   leftIcon: "ion-ios-settings"
	// },
];
export default options;
