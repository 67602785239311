import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: props.location },
					}}
				/>
			)
		}
	/>
);
// function PrivateRoute({ component: Component, authed, ...rest }) {
// 	alert(authed);
// 	return (
// 		<Route
// 			{...rest}
// 			render={(props) =>
// 				authed ? (
// 					<Component {...props} />
// 				) : (
// 					<Redirect
// 						to={{
// 							pathname: "/customer-portal/sign_in",
// 							state: { from: props.location },
// 						}}
// 					/>
// 				)
// 			}
// 		/>
// 	);
// }
const PublicRoutes = ({ history, isLoggedIn }) => {
	// const userData = JSON.parse(localStorage.getItem("customerLoggedIn"));

	return (
		<ConnectedRouter history={history}>
			<Switch>
				{/* <PrivateRoute
					authed={userData}
					exact
					path={"/customer-portal/customer-ship-detail/:id"}
					component={asyncComponent(() =>
						import("./containers/PublicPage/Customer")
					)}
				/> */}
				<Route
					exact
					path={"/customer-portal/customer-ship-detail"}
					component={asyncComponent(() => import("./containers/PublicPage/Customer"))}
				/>
				<Route
					exact
					path={"/customer-portal/inventory/:id"}
					component={asyncComponent(() => import("./containers/PublicPage/viewJobInventory"))}
				/>

				<Route
					exact
					path={"/customer-portal/sign_in"}
					component={asyncComponent(() => import("./containers/PublicPage/CustomerSignIn"))}
				/>
				<Route
					exact
					path={"/customer-portal/reset_password"}
					component={asyncComponent(() => import("./containers/PublicPage/ResetPassword"))}
				/>

				<Route
					exact
					path={"/customer-portal/change_password/:id?/:id2?/:id3?"}
					component={asyncComponent(() => import("./containers/PublicPage/ChangePassword"))}
				/>
				<Route exact path={"/"} component={asyncComponent(() => import("./containers/Page/signin"))} />
				<Route
					exact
					path={"/signin"}
					component={asyncComponent(() => import("./containers/Page/signin"))}
				/>

				<Route
					exact
					path={"/forgot-password"}
					component={asyncComponent(() => import("./containers/Page/forgotPassword"))}
				/>

				<Route
					exact
					path={"/admin-reset-password"}
					component={asyncComponent(() => import("./containers/Page/adminResetPassword"))}
				/>

				<Route
					path={"/reset-password"}
					component={asyncComponent(() => import("./containers/Page/resetPassword"))}
					exact={false}
				/>
				<Route
					path={"/reset-success"}
					component={asyncComponent(() => import("./containers/Page/resetSuccess"))}
				/>
				<Route
					path={"/signin-with-company"}
					component={asyncComponent(() => import("./containers/Page/signInWithCompanyId"))}
				/>
				<RestrictedRoute path='/' component={App} isLoggedIn={isLoggedIn} />
			</Switch>
		</ConnectedRouter>
	);
};

export default connect((state) => ({
	isLoggedIn: state.Auth.authToken !== null,
}))(PublicRoutes);
