export const BASEURL_API = process.env.REACT_APP_BASE_URL;
export const SUPER_ADMIN_COMPANYID = process.env.REACT_APP_SUPER_ADMIN_COMPANY_ID
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
// export const GOOGLE_API_KEY = "AIzaSyB-yGpC4YxIsgGFtmWp19CAJkza_XVFpN4"

//for staging and production
export const MOVER_STORAGE_API_URL = process.env.REACT_APP_MOVER_STORAGE_API_URL

//for local server
// const MOVER_STORAGE_API_URL = "https://storage-genie-api.apps.openxcell.dev/api/"

