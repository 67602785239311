import { message } from "antd";
import axios from "axios";
import authAction from "../redux/auth/actions";
import { store } from "../redux/store";
import { BASEURL_API } from "../static/data/constants";
import { CODES } from "./status-codes";

const { logout } = authAction;

const METHOD = {
	GET: "get",
	POST: "post",
	PUT: "put",
	DELETE: "delete",
};

const BASEURL = BASEURL_API;
// const BASEURL = "http://localhost:8421/";

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
	constructor() {
		this.baseURL = BASEURL;
		this.getUserType();
	}

	getUserType() {
		if (localStorage.getItem("userType")) this.userType = localStorage.getItem("userType");
	}

	// URL FOR API
	// REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
	get(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.GET, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	post(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.POST, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	put(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.PUT, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	delete(url, data) {
		return new Promise((resolve, reject) => {
			this.api(METHOD.DELETE, url, data)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}

	api(method, url, data) {
		return new Promise((resolve, reject) => {
			let axiosConfig = {};
			axiosConfig.method = method;
			axiosConfig.url = this.baseURL + url;
			axiosConfig.headers = this.setHeaders(data);
			if (data) {
				if (data.params) axiosConfig.params = data.params;
				if (data.data && data.data.responseType && data.data.responseType === "blob")
					axiosConfig.responseType = "blob";
				if (data.data) axiosConfig.data = data.data;
			}

			axios(axiosConfig)
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					//DEFAULT ERROR HANDLING

					if (error.response) {
						if (error.response.status === CODES.UNAUTHORIZED) {
							store.dispatch(logout());
							// window.location = "/";
							message.error(error.response.data.message);
						} else if (error.response.data.email) {
							message.error(error.response.data.email.msg);
						} else if (error.response.data.password) {
							message.error(error.response.data.password.msg);
						} else if (error.response.status === CODES.VALIDATION_ERROR)
							message.error(error.response.data.message);
						else if (error.response.data.message) message.error(error.response.data.message);
					}
					console.error("TCL: Api -> api -> error", error);
					reject(error);
				});
		});
	}

	setHeaders(data) {
		let headers = {};
		headers["accept-language"] = "en";
		headers["device_type"] = "CMS";
		headers["Content-Type"] = "application/json";

		if (data) {
			if (data.isMultipart) {
				headers["Content-Type"] = "multipart/form-data";
			}

			if (data.headers) {
				for (var key in data.headers) {
					if (data.headers.hasOwnProperty(key)) {
						headers[key] = data.headers[key];
					}
				}
			}
		}

		if (localStorage.getItem("isLogin") === "true" && !(data && data.skipAuth)) {
			headers["access_token"] = localStorage.getItem("access_token");
			headers["admin_id"] = localStorage.getItem("adminid");
		}

		if (this.userType) {
			headers["user_type"] = this.userType;
		}

		return headers;
	}
}

// SAMPLE DATA JSON
/* let sample_data = {

		// ADDITIONAL HEADERS IF REQUIRED
		headers :{
				'Content-type'  : 'xx-urlencode',
		},

		// IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
		// DEFAULT : FALSE;
		skipAuth    : false,

		// IF Default error handling needs to be overridden
		// DEFAULT : FALSE;
		skipErrorHandling    : false,

		// FOR SENDING FILES OR FORM DATA REQUEST
		isMultipart : true,

		// `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
		params : {
				user_id     : 10,
				name        : "lorem",
				page        : 3,
				sort_by     : 'name'
		},

		// POST DATA
		data : {
				firstName   : 'Lorem',
				lastName    : 'Ipsum'
		},
} */

export default Api;
