import { all, takeEvery, put, fork, } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import actions from './actions'
// import { getXapiKey } from '../../utils/getApikey'

export function* loginRequest () {
  yield takeEvery('LOGIN_REQUEST', function* () {})
}

export function* loginSuccess () {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token)
  })
}

export function* loginError () {
  yield takeEvery(actions.LOGIN_ERROR, function* () {})
}

export function* logout () {
  yield takeEvery(actions.LOGOUT, function* () {
    yield put(push('/'))
  })
}

export function* checkAuthorization () {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const isLogin = localStorage.getItem('isLogin')
    if (isLogin && isLogin !== 'false') {
      const access_token = localStorage.getItem('access_token')
      if (!access_token) {
        yield put({
          type: actions.LOGOUT
        })
      }
    }
    // const xapikey = localStorage.getItem('apikey')
    // if (
    //   xapikey !== undefined &&
    //   xapikey !== 'undefined' &&
    //   xapikey !== 'null' &&
    //   xapikey !== null &&
    //   xapikey !== ''
    // ) {
    //   yield dispachXapiKey(xapikey)
    // } else {
    //   const newXapiKey = yield call(getXapiKey)
    //   localStorage.setItem('apikey', newXapiKey)
    //   yield dispachXapiKey(newXapiKey)
    // }
  })
}

export default function* rootSaga () {
  yield all([
    fork(checkAuthorization),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ])
}
