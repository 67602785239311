import actions from "./actions";

const initState = {
	isLogin: localStorage.getItem("isLogin")
		? localStorage.getItem("isLogin") === "true"
			? true
			: false
		: false,
	access_token: localStorage.getItem("access_token")
		? localStorage.getItem("access_token")
		: "",
	adminid: localStorage.getItem("adminid")
		? localStorage.getItem("adminid")
		: "",
	fname: localStorage.getItem("fname") ? localStorage.getItem("fname") : "",
	lname: localStorage.getItem("lname") ? localStorage.getItem("lname") : "",
	company: localStorage.getItem("company")
		? localStorage.getItem("company")
		: "",
	companyID: localStorage.getItem("companyID")
		? localStorage.getItem("companyID")
		: "",
	staffId: localStorage.getItem("staffId")
		? localStorage.getItem("staffId")
		: "",
	pdf_time_stamp_checked: localStorage.getItem("pdf_time_stamp_checked") ? localStorage.getItem("pdf_time_stamp_checked") : "",
};

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				access_token: action.access_token,
				isLogin: true,
				adminid: action.adminid,
				fname: action.fname,
				lname: action.lname,
				company: action.company,
				companyID: action.companyID,
				staffId: action.staffId,
				pdf_time_stamp_checked: action.pdf_time_stamp_checked,
			};
		case actions.SET_XAPIKEY:
			return {
				...state,
				apikey: action.apikey,
			};
		case actions.UPDATE_PROFILE:
			return {
				...state,
				name: action.name,
			};
		case actions.LOGOUT:
			return {
				...state,
				isLogin: false,
				access_token: "",
				adminid: "",
				fname: "",
				lname: "",
				company: "",
				companyID: "",
				staffId: "",
				pdf_time_stamp_checked: "",
			};
		default:
			return state;
	}
}
