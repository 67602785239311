import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
	{
		path: "/customer-ship-detail/:id",
		component: asyncComponent(() => import("../PublicPage/Customer")),
	},
	{
		path: "/dashboard",
		component: asyncComponent(() => import("../dashboard")),
	},

	{
		path: "/company",
		component: asyncComponent(() => import("../Company/index")),
		exact: false,
	},

	{
		path: "/staff",
		component: asyncComponent(() => import("../Staff/index")),
		exact: false,
	},

	{
		path: "/group",
		component: asyncComponent(() => import("../group/index")),
		exact: false,
	},

	{
		path: "/customer",
		component: asyncComponent(() => import("../Customer/index")),
		exact: false,
	},

	{
		path: "/shipment-type",
		component: asyncComponent(() => import("../ShipmentType/index")),
		exact: false,
	},
	{
		path: "/room-list",
		component: asyncComponent(() => import("../RoomList/index.js")),
		exact: false,
	},
	{
		path: "/item-list",
		component: asyncComponent(() => import("../ItemList/index.js")),
		exact: false,
	},
	{
		path: "/tag",
		component: asyncComponent(() => import("../Tags/index")),
		exact: false,
	},
	{
		path: "/generic-label",
		component: asyncComponent(() => import("../GenericLabels/index")),
		exact: false,
	},

	{
		path: "/job",
		component: asyncComponent(() => import("../Job/index")),
		exact: false,
	},

	{
		path: "/qr-code",
		component: asyncComponent(() => import("../QrCode/index")),
		exact: false,
	},

	{
		path: "/assign-worker",
		component: asyncComponent(() => import("../AssignWorker/index")),
		exact: false,
	},

	{
		path: "/change-password",
		component: asyncComponent(() => import("../ChangePassword/ChangePassword")),
		exact: false,
	},
	{
		path: "/external-api-user",
		component: asyncComponent(() => import("../ExternalApi/index.js")),
		exact: false,
	},

	{
		path: "/",
		component: asyncComponent(() => import("../Widgets/index.js")),
		exact: false,
	},

	// {
	//   path: '/staffs',
	//   component: asyncComponent(() => import('../Staffs/Staffs')),
	//   exact : false
	// },

	// {
	//   path: '/settings',
	//   component: asyncComponent(() => import('../Settings/Settings')),
	//   exact : false
	// },
	// {
	//   path: '/404',
	//   component: asyncComponent(() => import('../Page/404')),
	//   exact : false
	// },
];

class AppRouter extends Component {
	render() {
		const { style } = this.props;
		return (
			<div style={style}>
				<Switch>
					{routes.map((singleRoute) => (
						<Route
							exact={singleRoute.exact}
							key={singleRoute.path}
							path={singleRoute.path}
							{...singleRoute}
						/>
					))}
				</Switch>
			</div>
		);
	}
}

export default AppRouter;
